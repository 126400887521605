import React from 'react'
import '../Footer.scss'


const Footer = ({ BgColor }) => {
    return (
        <div style={{boxShadow:"rgb(80 79 79 / 10%) -10px -10px 15px, 10px 10px 15px rgba(0, 0, 0, 0.8) "}} className={`footer-w content-footer`}>
        <footer className="footer_container">
                <div className="footer-item" >
                <img className="header_logo img_color f-mb contact_footer_img" src="https://spin-dd.com/wp-content/uploads/2020/03/spin_logo_w.png" alt="会社のロゴです" />
                <h5 className="footer-sub-text content_footer_sub">SPIN D&Dは【System Integration】【VR Development】【3DCG Design】の３つの分野を融合した特殊部隊です。
                SPIN D&Dは３つの分野のスペシャリストたちがそれぞれの得意とする領域をもちより、技術をコラボレーションさせることで、より先進的なサービス、ツールを開発させています。
                </h5>
            </div>
            <div className="footer-item">
                <h5 className="footer-title-text content_footer f-mb">SPIN　NEWS</h5>
                <p className="footer-sub-text content_footer_sub">pano2VRを試してみた</p>    
                <p className="footer-sub-text content_footer_sub">USDZを使ったWebARの作り方 2020/05/07</p>
                <p className="footer-sub-text content_footer_sub">USDZを使ったWebARの作り方 2020/05/07</p>
                <p className="footer-sub-text content_footer_sub">USDZを使ったWebARの作り方 2020/05/07</p>
            </div>
            <div className="footer-item">
                <h5 className="footer-title-text f-mb content_footer">CONTACT</h5>
                <p className="footer-sub-text content_footer_sub">〒113-0033<span></span>東京都文京区本郷1-21-5第四太平ビル2F</p>
                <p className="footer-sub-text content_footer_sub"><span>Phone:</span>(03)-5615-8845</p>
                <p className="footer-sub-text content_footer_sub"><span>Fax:</span>(03)-5615-8846</p>
                <p className="footer-sub-text content_footer_sub"><span>Email:</span>contact@spin-dd.com</p>
                <p className="footer-sub-text content_footer_sub"><span>Web:</span>https://spin-dd.com</p>
            </div>
        </footer>
        </div>
    )
}

export default Footer